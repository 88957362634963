
import * as ST from "@suid/types";
import { Tabs, Tab } from '../components/SmallComponents/SmallTabs';
import { isExtraSmall, isSmall, isMedium } from "../../utils/sizeUtil"

import { CloseIcon } from '../components/icons';
import { For, createSignal, Show, } from "solid-js";
import {
  IconButton, Button, Typography, Badge, Alert,
  Card, Stack, Divider, Popover, FormControl, FormControlLabel, RadioGroup, Radio,
  DialogActions, Box, Grid
} from "@suid/material";

import { AccountMenu } from '../components/AppMenuBar/AppBarMenu';
import { CardWithMinimize } from '../components/AppMenuBar/CardList'

import { ShowHeading } from '../components/SmallComponents/SmallHeader'
import { getCompanyLogo } from '../../utils/getCompanyLogo'
import { AvatarWithWfButtons, showWfCustomerToDoOpenItems, showWfCustomerMatchingOpendItems } from '../components/SmallComponents/SmallAvatarWithWfButtons'
import { TeamMembersList } from '../components/SmallComponents/SmallFieldTeamMembers';
import { getDummyAvatar } from '../../utils/getdummyavatar'
import { getText } from '../components/SmallComponents/Translate'
import { useNavigate } from "@solidjs/router";
import { isLoggedIn } from '../../utils/isloggedin'


const ShowSharePage = () => {

  const navigate = useNavigate();

  if (!isLoggedIn()) {
    navigate('/login');
    return;
  }

  const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);

  const handlePopoverOpen = (event: { currentTarget: Element }) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };


  const pageMargin = ((isExtraSmall() || isSmall()) ? 1 : 2)
  const sharingDirection = (isExtraSmall() || isSmall() || isMedium() ? "column" : "row")
  const cardType = (isExtraSmall() || isSmall() || isMedium() ? "list" : "stack")

  const open = () => Boolean(anchorEl());
  const [openFavourits, setOpenFavourits] = createSignal(false);
  const handleOpen = () => setOpenFavourits(true);

  const handleClose = () => {
    setOpenFavourits(false);
    //navigate('/home')
    window.history.go(-1)
  }

  const [viewFromTeamMemberEnabled, setViewFromTeamMemberEnabled] = createSignal(false);
  const [filter, setFilter] = createSignal("viewAll");
  const handleFilterChange = (event: ST.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
    event.target.value === "viewFromMember" ? setViewFromTeamMemberEnabled(true) : setViewFromTeamMemberEnabled(false)
  };

  const [avatarSrc, setAvatarSrc] = createSignal(1);


  const [activeTab, setActiveTab] = createSignal<number>(0)

  const handleOpenCV = () => {
    navigate('/customercv')
  }
  const handleOpenJob = () => {
    navigate('/customerjob')
  }

  const avatarGrossList = [
    { type: "Teddy Hansen", src: "/assets/imgs/avatar2.jpg", new: true },
    { type: "Peter Pan", src: getDummyAvatar(), new: true },
    { type: "Winny The Poo", src: getDummyAvatar() },
    { type: "Sahra Winterh", src: getDummyAvatar() },
    { type: "Kid the Bil", src: getDummyAvatar() },
    { type: "Sunshi Salmon", src: getDummyAvatar() },
    { type: "Berny Sanson", src: getDummyAvatar() },
    { type: "Kentucy Fried", src: getDummyAvatar() },
    { type: "Fried Banana", src: getDummyAvatar() },
    { type: "Carot Long", src: getDummyAvatar() },
    { type: "Åse Åen", src: getDummyAvatar() },
    { type: "Cathy Candy", src: getDummyAvatar() },
  ]
  const jobGrossList = [

    { name: "S/4 Upgrade", subText: "4 open roles", sharedBy: "Tim Hansen", comment: "We have to find a PM within 1 week.", src: getCompanyLogo("maersk.com"), new: true },
    { name: "S/4 Migration", subText: "3 open roles", sharedBy: "Tim Hansen", comment: "", src: getCompanyLogo("danfoss.com") },
    { name: "Payroll Rollout", subText: "1 contract ready", sharedBy: "Ida Bengson", comment: "We are in a hurry. No payrol 2 months from now", src: getCompanyLogo("bbc.co.uk") },
    { Name: "AI Enablement", subText: "2 interviews", sharedBy: "Ida Bengson", comment: "", src: getCompanyLogo("lego.com") },
    { Name: "Database Upgrade", subText: "1 extension", sharedBy: "Tim Hansen", comment: "Pls. look into", src: getCompanyLogo("lego.com") },

  ]

  const txtShare = getText("sharing", "share")
  const txtShareFrom = getText("sharing", "sharedfrom")
  const txtShareWith = getText("sharing", "sharedwith")
  const txtViewAll = getText("sharing", "viewall")
  const txtViewNew = getText("sharing", "viewnew")
  const txtViewWeek = getText("sharing", "viewweek")
  const txtViewMonth = getText("sharing", "viewmonth")
  const txtViewFromTeam = getText("sharing", "viewfromteam")
  const txtViewToTeam = getText("sharing", "viewtoteam")
  const txtYourTeamMembers = getText("sharing", "yourteammembers")
  const txtAlert = getText("sharing", "alert", [{ name: 'newCandidates', value: '4' }, { name: 'totalCandidates', value: '20' }, { name: 'newJobs', value: '12' }, { name: 'totalJobs', value: '87' }])
  const txtCandidates = getText("default", "candidates")
  const txtSharedBy = getText("default", "sharedby")
  const txtSharedWith = getText("default", "sharedwith")
  const txtComment = getText("default", "comment")
  const txtToday = getText("default", "today")
  const txtOpenRoles = getText("default", "openroles", [{ name: 'openRoles', value: '2' }])
  const txtJobs = getText("default", "jobs")
  const txtClose = getText("default", "close")
  const txtIntro = getText("sharing", "intro")

  const handleOpenFavourits = () => {
    setOpenFavourits(true)
    { txtIntro() }

  }


  type AvatarItemProps = {
    item: {
      src: string,
      type?: string,
      name?: string,
      sharedBy?: string,
      comment?: string,
      new?: boolean
    },
    handleOpen: () => void,
    isCandidate: boolean
  };

  const AvatarItem = (props: AvatarItemProps) => {
    
    return (
      <>
        <Stack direction="column" sx={{ margin: 1 }}>
          <Button >
            <Show when={props.item.new} fallback={
              <AvatarWithWfButtons avatarSize="medium" showWfItems={props.isCandidate ? showWfCustomerMatchingOpendItems : showWfCustomerToDoOpenItems}
                avatarName={props.isCandidate ? props.item.type : props.item.name}
                jobName={""}
                role={""}
                src={props.item.src}
              />}>
              <Badge badgeContent={''} color="error">
                <AvatarWithWfButtons avatarSize="medium" showWfItems={props.isCandidate ? showWfCustomerMatchingOpendItems : showWfCustomerToDoOpenItems}
                  avatarName={props.isCandidate ? props.item.type : props.item.name}
                  jobName={""}
                  role={""}
                  src={props.item.src}
                />
              </Badge>
            </Show>
          </Button>

          <Stack sx={{ margin: 1, alignItems: 'left', display: 'flex' }}>
            <Divider />
            <Typography fontSize="80%" variant="body1" color="text.primary">
              <b>{txtComment()}</b><br />
              {props.isCandidate ? "bl.a. bl.a." : props.item.comment}
            </Typography>
            <br />
            <Typography fontSize="70%" variant="body2" color="text.secondary">
              <b>{activeTab() === 0 ? txtSharedBy() : txtSharedWith()}</b><br />
              {props.isCandidate ? "Jim Hanse" : props.item.sharedBy} <br />
              {txtToday()} 11:03PM
            </Typography>
          </Stack>
          
        </Stack>
      </>
    )
  }

  type RenderListProps = {
    headerText: string,
    list: Array<AvatarItemProps['item']>,
    isCandidate: boolean
  };

  const  RenderList = (props: RenderListProps) =>{
    return (
      <Grid container item xs={12} sm={12} md={12} lg={6} xl={6} alignItems="center">
        <CardWithMinimize header={props.headerText} type={cardType} defaultOpen={true}>
          <Stack direction={sharingDirection} alignItems="left" spacing={1}>
            <For each={props.list}>
              {(item) => (
                <AvatarItem item={item} handleOpen={props.isCandidate ? handleOpenCV : handleOpenJob} isCandidate={props.isCandidate} />
              )}
            </For>
          </Stack>
        </CardWithMinimize>
      </Grid>
    )
  };

  return (
    <>
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={open()}
        anchorEl={anchorEl()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          aria-owns={open() ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {txtShare()}
        </Typography>

      </Popover>



      {/* ********************** */}
      {/* Open  box        */}
      {/* ********************** */}

      <AccountMenu />


      <ShowHeading pxValue={4} >{txtShare()}</ShowHeading>

      <Divider />

      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}>


        <Card sx={{ margin: pageMargin }}>


          <Box sx={{ margin: 1, display: 'flex', }} >

            <IconButton onClick={handleClose} > <CloseIcon /> </IconButton>

            <Tabs activeTab={activeTab()} setActiveTab={setActiveTab}>
              <Tab >{txtShareFrom()}</Tab>
              <Tab >{txtShareWith()}</Tab>
            </Tabs>
          </Box>

          <Divider />

          <CardWithMinimize header="Filters" type="list" defaultOpen={false} >
            <Grid container direction="column">

              <Grid item xs={12} md={6} alignItems="left" margin={2}>

                <Stack direction="row">
                  <Stack direction={sharingDirection} >
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="filter-group"
                        defaultValue="viewAll"
                        name="filter-group"
                        value={filter()}
                        onChange={handleFilterChange}
                      >

                        <FormControlLabel value="viewAll" control={<Radio />} label={txtViewAll()} />
                        <FormControlLabel value="viewNewOnly" control={<Radio />} label={txtViewNew()} />
                        <FormControlLabel value="viewWeek" control={<Radio />} label={txtViewWeek()} />
                        <FormControlLabel value="viewMonth" control={<Radio />} label={txtViewMonth()} />
                        <Show
                          when={activeTab() === 0}
                          fallback={
                            <FormControlLabel value="viewToMember" control={<Radio />} label={txtViewToTeam()} />
                          }
                        >
                          <FormControlLabel value="viewFromMember" control={<Radio />} label={txtViewFromTeam()} />
                        </Show>

                      </RadioGroup>
                    </FormControl>

                    <Show
                      when={viewFromTeamMemberEnabled()}
                    >
                      <FormControl>
                        <TeamMembersList id="teamMember" label={txtYourTeamMembers() as string} />

                      </FormControl>
                    </Show>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>

            <Alert severity="info">{txtAlert() as string} </Alert>

          </CardWithMinimize>


          <Grid container direction="column">
            <Stack direction="column" margin={2} justifyContent='flex-start' alignItems='stretch'>
              <RenderList headerText={txtCandidates() as string+ " (" + avatarGrossList.length + ")"} list={avatarGrossList} isCandidate={true} />
              <RenderList headerText={txtJobs() as string+ " (" + jobGrossList.length + ")"} list={jobGrossList} isCandidate={false} />
            </Stack>
          </Grid>


          <DialogActions>
            <Divider />
            <Button onClick={handleClose} variant="outlined">
              {txtClose()}
            </Button>
          </DialogActions>
        </Card>
      </Box>
    </>
  );
}

export { ShowSharePage }
